<template>
  <div class="courts-list md:w-1/2 mx-auto fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="courtsListTable"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getCourts()"
                             @row:clicked="showUser"
                             v-model="data"/>

    <!-- insert new court prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertNewCourtPromptStatus"
      @close="insertNewCourtPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCourtBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('courts.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertNewCourtPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <insert-court :in-modal="true" @insert="reloadCourtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show court details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCourtPromptStatus"
      @close="showCourtPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('court.update')" @click="editCourtPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            <!-- {{ $t('courts.profile.dynamicTitle', {name: selectedCourt.name}) }} -->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCourtPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <court-profile :court-id="selectedCourt.id"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- edit user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editCourtPromptStatus"
      @close="editCourtPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('EditCourtBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('courts.edit.dynamicTitle', {name: selectedCourt.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editCourtPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-court :court-id="selectedCourt.id" @edit="reloadCourtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertNewCourt" v-show="false" @click="insertNewCourtPromptStatus = true"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getCourts} from '@/http/requests/courts'
import {checkUserPermissions, getAvatarUrl} from '../../../../assets/js/functions'
import InsertCourt from '../insert/insertCourt'
import EditCourt from '../edit/editCourt'
import CourtProfile from '../profile/courtsProfile'
import CustomIcon from '../../../../components/customIcon/customIcon'

export default {
  name: 'courtsList',
  metaInfo () {
    return {
      title: this.$t('courts.list.title')
    }
  },
  components: {
    CustomIcon,
    CourtProfile,
    EditCourt,
    InsertCourt,
    DraggableDynamicTable
  },
  data () {
    return {
      requestSent: false,
      insertNewCourtPromptStatus: false,
      showCourtPromptStatus: false,
      editCourtPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'courtsListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'type',
          i18n: 'courts.list.table.header.type',
          width: 'calc((100% - 160px) / 3)',
          minWidth: 120,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('courts.types.all'),
              value: 0
            },
            {
              label: this.$t('courts.types.hard'),
              value: 1
            },
            {
              label: this.$t('courts.types.ground'),
              value: 2
            },
            {
              label: this.$t('courts.types.grass'),
              value: 3
            }
          ]
        },
        {
          field: 'name',
          i18n: 'courts.list.table.header.name',
          width: 'calc((100% - 160px) / 3 * 2)',
          minWidth: 250,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'image',
          i18n: 'courts.list.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90,
          align: 'center'
        },
        {
          field: 'row',
          i18n: 'courts.list.table.header.row',
          width: '70px',
          minWidth: 70,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,asc'],
      selectedCourt: {},
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewCourt',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'court.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: {name: 'courtsTrash'},
              type: 'link',
              icon: 'icon-trash',
              iconPack: 'feather',
              permission: 'court.delete'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', ' md:w-1/2 mx-auto')
    }, 50)

    this.getCourts()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getCourts () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.courtsListTable && this.data.length === 0) {
              this.$refs.courtsListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.courtsListTable && this.data.length > 0) {
              this.$refs.courtsListTable.loadMoreStatus = 'Loading'
            }

            getCourts(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              res.data.data.forEach((court) => {
                let courtType = ''
                if (court.type === 1) {
                  courtType = this.$t('courts.types.hard')
                } else if (court.type === 2) {
                  courtType = this.$t('courts.types.ground')
                } else if (court.type === 3) {
                  courtType = this.$t('courts.types.grass')
                }

                this.data.push({
                  row: this.data.length + 1,
                  id: court.id,
                  registryDate: court.created_at,
                  gender: court.gender === 1 ? this.$t('genderTypes.man') : this.$t('genderTypes.woman'),
                  phoneNumber: court.phone_number ? court.phone_number : '',
                  name: court.name || '',
                  type: courtType,
                  // company: court.company ? court.company : '',
                  image: {
                      type: 'img',
                      src: court.avatar ? getAvatarUrl(court.avatar) : require('@/assets/images/unnamed.png'),
                      class: 'court-image'
                  },
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.courtsListTable) this.$refs.courtsListTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch(() => {
                if (this.$refs.courtsListTable) this.$refs.courtsListTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    showUser (court) {
      this.selectedCourt = court
      this.showCourtPromptStatus = true
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {
        case 'name':
          if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
          break

        case 'type':
          if (filters[key].search.value > 0) filters_list.push(`type=${  filters[key].search.value}`)
          break
        }
      })
      filters_list.push('characters=1')

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getCourts()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getCourts()
    },
    reloadCourtsList () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getCourts()
      this.editCourtPromptStatus = false
      this.insertNewCourtPromptStatus = false
      this.showCourtPromptStatus = false
    },
    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.usersChanged': {
      handler (val) {
        if (val) {
          this.page = 1
          this.endedList = false
          this.filters = []
          this.sorts = ['order[0]=created_at,desc']
          this.data = []
          this.insertNewCourtPromptStatus = false
          this.editCourtPromptStatus = false
          this.showCourtPromptStatus = false
          this.getCourts()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .courts-list {
    .draggable-dynamic-table {
      .court-image {
        height: 50px;
      }
    }
  }
</style>
